<template>
    <div class="bcView successView" v-if="visible">
        <div class="paymentView" style="height: 100%">
            <div class="middleView" style="height: 240px;">
                <img class="img-success"
                     src="https://static.bokao2o.com/wisdomCashier/images/Payment_Success_Order.png">
                <div class="textView">{{title}}</div>
            </div>
        </div>
        <div class="bottomView">
            <button @click="checkoutSuccess">确认</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            title: {
                type: String,
                default: '结账成功!'
            },
            visible: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {};
        },
        methods: {
            checkoutSuccess() {
                this.$emit('success')
            },
        }
    }
</script>

<style scoped lang="scss">

    div {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-user-select: none;
    }

    button {
        background: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
    }

    .successView {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 900;
        text-align: center;

        > .paymentView {
            position: relative;

            > .middleView {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;

                > .img-success {
                    width: 176px;
                    height: 176px;
                }

                > .textView {
                    margin-top: 35px;
                    line-height: 25px;
                    font-family: PingFangSC-Semibold;
                    font-size: 18px;
                    color: #333;
                }
            }
        }

        > .bottomView {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 800px;
            margin: auto;
            text-align: center;
            padding: 20px 35px 25px 35px;
            border-top: 0.5px solid rgba(154, 154, 154, 0.5);

            > button {
                width: 320px;
                font-family: PingFangSC-Semibold;
                font-size: 18px;
                color: #fff;
                background: #3370ff;
                border-radius: 6px;
                line-height: 44px;
            }
        }
    }
</style>
