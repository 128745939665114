<template>
    <div class="orderCheckoutView">
        <div class="bcView successView" v-if="successVisible">
            <div class="paymentView" style="height: 100%;">
                <div class="middleView" style="height: 240px;">
                    <img
                            class="img-success"
                            src="../../assets/navImages/Payment_Success_Order.png"
                    />
                    <div class="textView">现金结算 结账成功</div>
                    <div class="commission">
                        恭喜您,您本单获取提成金额：
                        <span
                        >￥{{
                            commissionPrice ? parseFloat(commissionPrice).toFixed(2) : 0
                            }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="bottomView">
                <button @click="getClothesOrderDetails">
                    打印
                </button>
                <button @click="checkoutSuccess">返回收银台</button>
                <button @click="lookOrder">查看订单</button>
            </div>
        </div>
    </div>
</template>

<script>
import CheckoutSuccess from "@/components/CheckoutSuccess";
import {getClothesOrderDetails} from "@/api/clothes/order";
import {getLodop} from "@/print/LodopFuncs";

export default {
    name: "paySuccess",
    components: {
        CheckoutSuccess,
    },
    data() {
        return {
            successVisible: true,
            orderDetailOption: {},
            orderNumber: "",
            commissionPrice: "",
        };
    },
    created() {

    },
    methods: {
        checkoutSuccess() {
            this.$router.push({name: "home"});
        },
        lookOrder() {
            this.$router.push({
                path: "/home/order",
                query: {
                    orderNumber: this.orderNumber,
                },
            });
        },
        // 打印收衣订单
        getClothesOrderDetails() {
            this.$confirm("确认要打印小票嘛?", "系统提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
            }).then(() => {
                getClothesOrderDetails(this.orderNumber).then((response) => {
                    console.log(response);
                    this.orderDetailOption = response.data.data;
                    this.printPaper();
                    this.printLabel();
                });
            });
        },
        getPrint(type) {
            let defaultName;
            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );

            let arr = [];
            let iPrinterCount = LODOP.GET_PRINTER_COUNT();
            for (let i = 0; i < iPrinterCount; i++) {
                arr.push({
                    label: LODOP.GET_PRINTER_NAME(i),
                    value: i,
                });
            }
            let labelList = [];
            if (type === 1) {
                //小票
                defaultName = LODOP.GET_PRINTER_NAME("-1"); //GET_PRINTER_NAME(intPrinterIndex);用序号获得打印机名，一般序号从0开始，-1特指默认打印机；
                arr.forEach((item) => {
                    if (item.label === defaultName) {
                        this.printIndex = item.value;
                    }
                });
            } else {
                //水洗唛
                labelList.push("TSC");
                labelList.push("水洗唛打印机");
                labelList.push("HPRT XT300");
                labelList.push("HPRT XT300 - ZPL");
                arr.forEach((item) => {
                    for (let i = 0; i < labelList.length; i++) {
                        if (item.label === labelList[i]) {
                            console.log(labelList[i]);
                            this.printIndex = item.value;
                        }
                    }
                });
            }
        },

        // 查询数据
        printPaper() {
            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );
            this.getPrint(1);

            LODOP.PRINT_INIT("收银小票"); //首先一个初始化语句
            LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");

            let height = 0;
            //公司名称
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "洗衣通");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            height = height + 10;
            //门店名称
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                this.orderDetailOption.storeName
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
            // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            //订单号条形码
            height = height + 6;
            LODOP.ADD_PRINT_BARCODE(
                height + "mm",
                "14mm",
                "50mm",
                "9mm",
                "128A",
                this.orderDetailOption.orderNumber
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
            //订单号
            height = height + 10;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "No." + this.orderDetailOption.orderNumber
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            // 线
            height = height + 6;
            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
            height = height + 6;
            //衣物信息标题
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "衣物信息");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            height = height + 6;
            //衣物列表：名称 服务 件数 原价 实收
            if (this.orderDetailOption.clothesList.length > 0) {
                LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "衣物号");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT(height + "mm", "16mm", "75mm", "4mm", "名称");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT(height + "mm", "29mm", "75mm", "4mm", "项目");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT(height + "mm", "40mm", "75mm", "4mm", "件数");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT(height + "mm", "50mm", "75mm", "4mm", "原价/实收");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                height = height + 5;
            }
            this.printList = this.orderDetailOption.clothesList.filter(
                (item) => item.accessoryNumber == null || item.accessoryNumber == ""
            );
            //衣物列表
            for (let i = 0; i < this.printList.length; i++) {
                let clothes = this.printList[i];
                LODOP.ADD_PRINT_TEXT(
                    height + i * 5 + "mm",
                    "0mm",
                    "75mm",
                    "4mm",
                    clothes.outClothesNum
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                LODOP.ADD_PRINT_TEXT(
                    height + i * 5 + "mm",
                    "16mm",
                    "75mm",
                    "4mm",
                    clothes.clothesName.length > 3
                        ? clothes.clothesName.substring(0, 3) + "*"
                        : clothes.clothesName
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                LODOP.ADD_PRINT_TEXT(
                    height + i * 5 + "mm",
                    "29mm",
                    "75mm",
                    "4mm",
                    clothes.clothesServiceName
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                LODOP.ADD_PRINT_TEXT(
                    height + i * 5 + "mm",
                    "40mm",
                    "75mm",
                    "4mm",
                    clothes.num
                ); //件数
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                LODOP.ADD_PRINT_TEXT(
                    height + i * 5 + "mm",
                    "50mm",
                    "75mm",
                    "4mm",
                    clothes.originalPrice + "/" + clothes.currentPrice
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                height = height;
            }

            height = height + this.printList.length * 5 + 1;

            //注意：*衣物详细信息请进入洗衣通小程序查看*
            // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "*衣物详细信息请进入洗衣通小程序查看*");
            // LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);

            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "总件数：");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "60mm",
                "75mm",
                "4mm",
                this.orderDetailOption.clothesNum
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "原价总额：");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "60mm",
                "75mm",
                "4mm",
                "￥" + this.orderDetailOption.orderOriginalPrice
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "优惠总额：");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "60mm",
                "75mm",
                "4mm",
                "￥" + this.orderDetailOption.discountPrice
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "折后总额：");
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "60mm",
                "75mm",
                "4mm",
                "￥" + this.orderDetailOption.actuallyPaidPrice
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            if (this.orderDetailOption.payWay) {
                LODOP.ADD_PRINT_TEXT(
                    height + "mm",
                    "0mm",
                    "75mm",
                    "4mm",
                    this.orderDetailOption.payWay + "："
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                LODOP.ADD_PRINT_TEXT(
                    height + "mm",
                    "60mm",
                    "75mm",
                    "4mm",
                    "￥" + this.orderDetailOption.actuallyPaidPrice
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                height = height + 5;
            }
            // 线
            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

            //收银信息
            // height = height + 4;
            // LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "收银信息");
            // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            // LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
            // LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

            //收银详情
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "客户姓名：" + this.orderDetailOption.orderRealName
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "联系电话：" + this.orderDetailOption.orderUserPhone
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            // 充值
            if (this.orderDetailOption.rechargeFlag === 2) {
                if (
                    this.orderDetailOption.cardNo === "" ||
                    this.orderDetailOption.cardNo === null
                ) {
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "会员卡号：" + "-"
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                } else {
                    height = height + 5;
                    LODOP.ADD_PRINT_TEXT(
                        height + "mm",
                        "0mm",
                        "75mm",
                        "4mm",
                        "会员卡号：" + this.orderDetailOption.cardNo
                    );
                    LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                }

                height = height + 5;
                LODOP.ADD_PRINT_TEXT(
                    height + "mm",
                    "0mm",
                    "75mm",
                    "4mm",
                    "卡内余额：" + this.orderDetailOption.balance
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            }
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "现金券余额：" + this.orderDetailOption.cashCouponBalance
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "收衣店长：" + this.orderDetailOption.storeUserName
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            // LODOP.ADD_PRINT_TEXT(height + "mm", "37.5mm", "75mm", "4mm", "取衣时间：" + this.orderDetailOption.takeTime);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "取衣时间：请咨询店长"
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "收衣时间：" + this.orderDetailOption.orderTime
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            //门店电话
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "3mm",
                "门店电话：" + this.orderDetailOption.store.phone
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            //门店地址
            if (this.orderDetailOption.store.address.length > 16) {
                height = height + 5;
                LODOP.ADD_PRINT_TEXT(
                    height + "mm",
                    "0mm",
                    "75mm",
                    "3mm",
                    "门店地址：" + this.orderDetailOption.store.address.substring(0, 16)
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
                height = height + 5;
                LODOP.ADD_PRINT_TEXT(
                    height + "mm",
                    "0mm",
                    "75mm",
                    "3mm",
                    this.orderDetailOption.store.address.substring(16)
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            } else {
                height = height + 5;
                LODOP.ADD_PRINT_TEXT(
                    height + "mm",
                    "0mm",
                    "75mm",
                    "3mm",
                    "门店地址：" + this.orderDetailOption.store.address
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            }
            //线
            height = height + 6;
            // 线
            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);
            //二维码
            height = height + 5;
            LODOP.ADD_PRINT_BARCODE(
                height + "mm",
                "25mm",
                "30mm",
                "30mm",
                "QRCode",
                "http://weixin.qq.com/r/QxBIUJzE6sGVrdgz90VR"
            );
            height = height + 25;

            //注：扫码进入小程序查看衣物详细信息
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "扫码进入公众号查看衣物详细信息"
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            //请妥善保管取衣凭证，如丢失请立即前往门店挂失
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "请妥善保管取衣凭证，如丢失请立即前往门店挂失"
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

            //投诉服务电话：400-816-5008
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "投诉服务电话：400-816-5008"
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

            LODOP.SET_PRINTER_INDEX(this.printIndex);
            LODOP.PRINT();
        },

        // 打印标签
        printLabel() {
            let list = this.orderDetailOption.clothesList;

            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );
            this.getPrint(2);

            for (let i = 0; i < list.length; i++) {
                let clothes = list[i];
                LODOP.PRINT_INIT("水洗唛"); //首先一个初始化语句
                LODOP.SET_PRINT_PAGESIZE(1, "110mm", "23mm", "");
                //一边30mm,65mm
                LODOP.ADD_PRINT_TEXT(
                    "0mm",
                    "7mm",
                    "70mm",
                    "10mm",
                    "品类：" + clothes.clothesName + " " + clothes.clothesServiceName
                ); //然后多个ADD语句及SET语句
                LODOP.ADD_PRINT_TEXT(
                    "0mm",
                    "65mm",
                    "33mm",
                    "10mm",
                    this.orderDetailOption.storeName + (clothes.suspensionNumber?'('+clothes.suspensionNumber+')':'')
                ); //然后多个ADD语句及SET语句
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.ADD_PRINT_TEXT(
                    "4mm",
                    "7mm",
                    "70mm",
                    "10mm",
                    "订单：" +
                    this.orderDetailOption.orderNumber +
                    " " +
                    this.orderDetailOption.orderTime
                ); //然后多个ADD语句及SET语句
                LODOP.ADD_PRINT_TEXT(
                    "8mm",
                    "7mm",
                    "70mm",
                    "10mm",
                    "附件：" + clothes.clothesAccessory
                ); //然后多个ADD语句及SET语句
                //条形码
                LODOP.ADD_PRINT_BARCODE(
                    "5mm",
                    "70mm",
                    "33mm",
                    "10mm",
                    "128C",
                    clothes.outClothesNum
                );
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
                let desc = "";
                if (clothes.colorName !== "") {
                    desc = desc + clothes.colorName + " ";
                }
                if (clothes.brandName !== "") {
                    desc = desc + clothes.brandName + " ";
                }
                if (clothes.effects !== "") {
                    desc = desc + clothes.effects + " ";
                }
                if (clothes.blemishes !== "") {
                    desc = desc + clothes.blemishes;
                }

                LODOP.ADD_PRINT_TEXT("12mm", "7mm", "65mm", "10mm", "描述：" + desc); //然后多个ADD语句及SET语句
                LODOP.ADD_PRINT_TEXT(
                    "15mm",
                    "65mm",
                    "33mm",
                    "10mm",
                    clothes.outClothesNum
                ); //然后多个ADD语句及SET语句
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINTER_INDEX(this.printIndex);
                LODOP.PRINT();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.orderCheckoutView {
  position: relative;
  width: 100%;
  background: #f8f8f8;
  height: 460px;
  overflow: hidden;

  > .successView {
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;

    > .paymentView {
      > .middleView {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto 60px;

        > .img-success {
          width: 120px;
          height: 120px;
        }

        > .textView {
          margin-top: 30px;
          line-height: 25px;
          font-family: PingFangSC-Semibold;
          color: rgba(16, 16, 16, 100);
          font-size: 18px;
        }
      }
    }

    > .bottomView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      margin: auto;
      text-align: center;
      // padding: 20px 35px 25px 35px;
      > button {
        width: 240px;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #fff;
        border-radius: 6px;
        line-height: 40px;
        margin-left: 20px;
        margin-bottom: 20px;
      }

      button:nth-child(1) {
        background: #FFFFFF;
        color: #000;
        border: 1px solid rgba(187, 187, 187, 100);
      }

      button:nth-child(2) {
        background-color: rgba(34, 119, 246, 100);
      }

      button:nth-child(3) {
        background-color: #FFFFFF;
        color: #2277F6;
      }
    }
  }
}

.commission {
  margin-top: 15px;
  color: rgba(16, 16, 16, 100);
  font-size: 18px;

  span {
    color: #f92c46;
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
